.alert-page {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
    margin-bottom: 20px;
}

.alert-process {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px;
}

li {
	padding: 0.5rem 0.5rem 0;
}

a.docs-email {
    color: inherit;
}