.login-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    color: white;
    margin-top: 10%;
    padding: 40px 0;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Adds shadow */
    border-radius: 10px; 
    width: 400px;
}

.login-ocra-icon {
    width: 300px;
    margin-bottom: 5vh;
}

.login-name {
    color: #3aa6e9;
}
