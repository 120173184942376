.header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.ocra-icon {
    position: relative;
    width: 120px;
    z-index: 10;
    margin-bottom: 10px;
}

.header-anchor {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    margin-right: 10px;
}

.navBar {
    display: flex;
    margin-left: auto;
    margin-top: auto;
}

.tab:hover {
    cursor: pointer;
    color: #3f8ece;
}

.tab {
    color: #3aa6e2;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: column;
    align-content: center;
}

.logout {
    color: #d30000;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-decoration: none;
    /* margin-left: 40px; */
    margin-top: 0.5%;
    margin-bottom: 5%;
    margin-left: 20px;
    flex-direction: column;
    align-content: center;
}

.logout:hover {
    cursor: pointer;
    color: #b22222;
}

.bar {
    position: relative;
    background-color: #3aa6e2;
    width: 40px;
    height: 2px;
}

.divider {
    background-color: #3aa6e2;
    height: 1.5px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 20;
    margin-bottom: 25px;
}
