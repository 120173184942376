table {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    border-collapse: collapse;
    width: 95%;
}

td {
    padding: 5px;
    max-width: 350px;
    font-size: 0.8em;
    text-align: left;
}

thead {
    color: white;
    font-weight: bold;
    font-size: 1em;
}

thead .header-text {
    background-color: #3aa6e2;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

thead tr {
    border-bottom: 1px solid lightgray;
}

thead,
td {
    height: 35px;
}

tbody tr {
    border-bottom: none; /* Remove border lines between rows */
}

tbody td {
    border-bottom: none; /* Remove border lines between rows */
}

td:nth-child(7) {
    text-align: left;
}

.fetching {
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
    margin-bottom: 10px;
}

.red_alert {
    background-color: #ffbaba;
}

.notesTextArea {
    min-width: 100%;
    max-width: 100%;
    min-height: 30px;
    font-family: inherit;
}

.resolve_button {
    font-family: inherit;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #3f8ece;
    color: white;
    border: 0px;
}

.resolve_button:hover {
    cursor: pointer;
    background-color: #a0d0c9;
}

.sort-field:hover {
    cursor: pointer;
}

.red-background {
    background-color: red;
    color: white;
    padding: 2px 8px;
    border-radius: 15px;
}

.yellow-background {
    background-color: yellow;
    color: black;
    padding: 2px 8px;
    border-radius: 15px;
}

.uppercase {
    text-transform: uppercase;
}



table a {
    color: inherit;
    text-decoration: none;
}

table a:hover {
    text-decoration: underline;
}

.asset {
    padding: 2px 8px;
    border-radius: 15px;
}

.asset-btc {
    background-color: yellow;
    color: black; 
}

.asset-eth {
    background-color: grey;
    color: white;
}

.asset-usdt {
    background-color: green;
    color: white;
}

.asset-usdc {
    background-color: skyblue;
    color: black;
}