.exchangeChart {
    text-align: center;
    width: 81%;
    height: 100%;
    position: relative;
}

.chartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: auto;
}