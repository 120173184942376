.optionsContainer {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    text-align: center;
    align-items: center;
}
.options {
    display: flex;
    margin-right: 20px;
    width: 70px;
    height: 30px;
    background-color: #3f8ece;
    color: white;
    border-radius: 10px;
    text-align: center;
}
