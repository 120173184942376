.App {
    font-family: Helvetica;
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
    min-height: 100vh;
}

.App > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.fetching {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}