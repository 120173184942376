.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer-logo img {
    width: 120px;
    height: auto;
}

.footer-divider {
    background-color: #3aa6e2;
    margin-top: 10px;
    height: 1.5px;
}
